<template>
    <div class="dashboard-container">
        <div class="header-container">
            <h3>Informasi Sistem</h3>
        </div>
        <hr class="bottom-devider"/>
        <div class="body-container">
            <CRow>
                
				<CCol>
                    <CCard>
                        <CCardHeader><h5>Informasi Penyimpanan</h5></CCardHeader>
                        <CCardBody>
                        <CRow><CCol>
                            <h6><b>Penggunaan Penyimpanan Memori: {{diskInfo.percent}}</b></h6>
                        </CCol></CRow>
                        <hr>
                        <CRow>
                            <CCol>
                                Total Memori Penyimpanan Tersedia
                            </CCol>
                            <CCol>
                                {{ diskInfo.total }}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Memori Penyimpanan Terisi
                            </CCol>
                            <CCol>
                                {{diskInfo.used}}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Memori Penyimpanan Belum Terisi
                            </CCol>
                            <CCol>
                                {{diskInfo.free}}
                            </CCol>
                        </CRow>
                        </CCardBody>
                    </CCard>
				</CCol>
                <CCol>
                    <CCard>
                        <CCardHeader><h5>Informasi CPU</h5></CCardHeader>
                        <CCardBody>
                        <CRow>
                            <CCol>
                                Total Core
                            </CCol>
                            <CCol>
                                {{ cpuInfo.totalCore }}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Total Pemanfaatan Memori
                            </CCol>
                            <CCol>
                                {{cpuInfo.totalUsage}}
                            </CCol>
                        </CRow>
                        <CRow>
                            <!-- Usage Data -->
                            <v-data-table
                                :loading="loading"
                                loading-text="Memuat Data..."
                                :headers="cpuInfo.headers"
                                :server-items-length="cpuInfo.totalCore"
                                :multi-sort="false"
                                :items="cpuInfo.core"
                                class="elevation-1">
                            </v-data-table>
                        </CRow>
                        </CCardBody>
                    </CCard>
				</CCol>
				<CCol>
                    <CCard>
                        <CCardHeader><h5>Informasi Memori</h5></CCardHeader>
                        <CCardBody>
                        <CRow><CCol>
                            <h6><b>Penggunaan Memori: {{ramInfo.percent}}</b></h6>
                        </CCol></CRow>
                        <hr>
                        <CRow>
                            <CCol>
                                Total Memori Server
                            </CCol>
                            <CCol>
                                {{ ramInfo.total }}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Memori Tersedia untuk Sistem
                            </CCol>
                            <CCol>
                                {{ramInfo.available}}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Memori Belum Terpakai
                            </CCol>
                            <CCol>
                                {{ramInfo.free}}
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                Memori Terpakai
                            </CCol>
                            <CCol>
                                {{ramInfo.used}}
                            </CCol>
                        </CRow>
                        </CCardBody>
                    </CCard>
				</CCol>
            </CRow>
        </div>
    </div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'
import { CChartPie, CChartHorizontalBar } from '@coreui/vue-chartjs'
export default {
    name: 'System Chart',

    data () {
        return {

            systemInfo: {
                'system': null,
                'release': null,
                'version': null,
                'machine': null
            },
            cpuInfo: {
                'totalCore': null,
                'totalUsage': null,
                'headers': [
                    { text: 'Core', value: 'name', align: 'center', sortable: true },
                    { text: 'Penggunaan', value: 'value', align: 'center'},
                ],
                'optionsTable': {},
                'core': []
            },

            ramInfo: {
                'total': null,
                'available': null,
                'free': null,
                'used': null,
                'percent': null,
                'pchart': []
            },

            diskInfo: {
                'total': null,
                'free': null,
                'used': null,
                'percent': null
            }
        }
    },

    mounted () {
        this.getSystemInfo()
    },

    methods: {
        getSystemInfo() {
            this.$store.dispatch({
				type: 'summary/getSystemData'
			}).then( res => {
                // cpu info
                var cpu_information = res.cpu_information
                this.cpuInfo.totalCore = cpu_information[1].value
                this.cpuInfo.totalUsage = cpu_information[2].value
                this.cpuInfo.core = []

                for (var i=3; i < cpu_information.length; i++){
                    let data = {
                        'core': cpu_information[i].name,
                        'usage': cpu_information[i].value
                    }
                    this.cpuInfo.core.push(cpu_information[i])
                }

                // ram info
                var ram_information = res.ram_information
                this.ramInfo.total = ram_information[0].value
                this.ramInfo.available = ram_information[1].value
                this.ramInfo.free = ram_information[2].value
                this.ramInfo.used = ram_information[3].value
                this.ramInfo.percent = ram_information[4].value

                // disk info
                var disk_information = res.disk_information
                this.diskInfo.total = disk_information[1].value
                this.diskInfo.used = disk_information[2].value
                this.diskInfo.free = disk_information[3].value
                this.diskInfo.percent = disk_information[4].value

            }).catch( err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getSystemInfo()
				}, 10000)
			})

        }
    },

    components: {
    }
}



</script>

<style scoped>

</style>